import React from 'react';
import { SocialIcon } from 'react-social-icons';

const BusinessCard = ({ name, phone, email, instagram }) => {
  return (
    <div className="card">
      <h1>{name}</h1>
      <SocialIcon url={instagram} label="Follow me on Instagram"/>
      <div className="tools">
        <div className="tool">
          <span role="img" aria-label="Rock">🪨</span>
          <span className="count">10</span>
        </div>
        <div className="tool">
          <span role="img" aria-label="Scissors">✂️</span>
          <span className="count">10</span>
        </div>
        <div className="tool">
          <span role="img" aria-label="Paper">📄</span>
          <span className="count">10</span>
        </div>
      </div>
    </div>
  );
};

export default BusinessCard;
