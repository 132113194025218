import React from 'react';
import BusinessCard from './components/BusinessCard';
import './App.css';
const App = () => {
  const instagram = process.env.REACT_APP_INSTAGRAM || 'https://instagram.com/qwitriy';
  const name = process.env.REACT_APP_NAME || "Diman The Best";

  return (
    <BusinessCard name={name} instagram={instagram}/>
  );
};

export default App;
